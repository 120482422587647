// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/daniel/Desktop/Airbnb Tuanify/Tuanify Website/tuanifyLP/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-booklet-js": () => import("/Users/daniel/Desktop/Airbnb Tuanify/Tuanify Website/tuanifyLP/src/pages/booklet.js" /* webpackChunkName: "component---src-pages-booklet-js" */),
  "component---src-pages-index-js": () => import("/Users/daniel/Desktop/Airbnb Tuanify/Tuanify Website/tuanifyLP/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qbo-1410-booklet-js": () => import("/Users/daniel/Desktop/Airbnb Tuanify/Tuanify Website/tuanifyLP/src/pages/QBO1410/booklet.js" /* webpackChunkName: "component---src-pages-qbo-1410-booklet-js" */),
  "component---src-pages-qbo-1410-index-js": () => import("/Users/daniel/Desktop/Airbnb Tuanify/Tuanify Website/tuanifyLP/src/pages/QBO1410/index.js" /* webpackChunkName: "component---src-pages-qbo-1410-index-js" */),
  "component---src-pages-urbn-1504-booklet-js": () => import("/Users/daniel/Desktop/Airbnb Tuanify/Tuanify Website/tuanifyLP/src/pages/URBN1504/booklet.js" /* webpackChunkName: "component---src-pages-urbn-1504-booklet-js" */),
  "component---src-pages-urbn-1504-index-js": () => import("/Users/daniel/Desktop/Airbnb Tuanify/Tuanify Website/tuanifyLP/src/pages/URBN1504/index.js" /* webpackChunkName: "component---src-pages-urbn-1504-index-js" */)
}

